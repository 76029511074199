import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import { useTourFeeData } from "../../hooks/tour-real-school-fee-time";

const ClassCurriculum = () => {

	const allData = useTourFeeData()
	let classCurriculumData = allData.filter((data) => data.slug === "Tour-Of-RealSchool")[0]

    return (
        <BoardsAndProgramStyled>
            <BoardsAndProgramWrapperStyled>
                <p key={`heading`} id="multiple_tracks_heading1" dangerouslySetInnerHTML={{ __html: classCurriculumData?.heading ?? "" }} />
                <p className="p-heading">{classCurriculumData?.sub_heading}</p>

                <BoardsAndProgramThreeWrapperStyled>
                    {classCurriculumData?.features?.map((card, index) => {
                        return (
                            <BoardsAndProgramCardWrapperStyled key={index}>
                                <div className="multiple_tracks_card_wrapper_one">
                                    <div className="multiple_tracks_card_wrapper_two">
										<span className="sa_wo_sa">{card.UpperText ?? ""}</span>
										<span className="sa_wo_sa_subheading">{card.LowerText ?? ""}</span>
                                    </div>
                                    <Link to={card.redirect_to ?? "/"}>
										<img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/Group 9739.png" />
                                    </Link>
                                </div>
                            </BoardsAndProgramCardWrapperStyled>
                        );
                    })}
                </BoardsAndProgramThreeWrapperStyled>
            </BoardsAndProgramWrapperStyled>
        </BoardsAndProgramStyled>
    );
};
const BoardsAndProgramStyled = styled.section``;
const BoardsAndProgramWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 3em;
	padding: 1em;
	padding-bottom: 7em;
	#multiple_tracks_heading1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2A264C;
	}
	.p-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-align: center;
		color: #2a264c;
	}
	@media (max-width: 500px) {
		#multiple_tracks_heading1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#multiple_tracks_heading2 {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 2.5em;
		}
	}
`;

const BoardsAndProgramThreeWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
		gap: 1.5em;
	}
`;

const BoardsAndProgramCardWrapperStyled = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 1.5em;
	padding: 1.25em;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	min-width: 21em;
	max-width: 21em;
	.multiple_tracks_card_wrapper_one {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 100%;
		.fa-circle-chevron-right {
			color: #F05C5C;
			font-size: 2.5rem;
			cursor: pointer;
			height: 2.4rem;
		}
	}
	.multiple_tracks_card_wrapper_two {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		gap: 0.25em;
		width: 100%;
	}

	.sa_wo_sa {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 29px;
		color: #2A264C;
	}
	.sa_wo_sa_subheading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #2A264C;
	}
	@media (max-width: 500px) {
		.sa_wo_sa_subheading {
			font-size: 0.875rem;
		}

		padding: 0.75em;
		gap: 1em;
	}

	@media (max-width: 370px) {
		min-width: 20em;
		max-width: 20em;
		.multiple_tracks_card_wrapper_one {
			gap: 2em;
		}
	}

	@media (max-width: 315px) {
		min-width: 15.5em;
		max-width: 15.5em;
		gap: 1em;

		.fa-circle-chevron-right {
			font-size: 2rem;
		}
		.sa_wo_sa {
			font-size: 1.2rem;
		}
		.sa_wo_sa_subheading {
			font-size: 0.775rem;
		}
	}
`;

export default ClassCurriculum;
