import { useStaticQuery, graphql } from "gatsby";

export const useProgramEnquiryData = () => {
  const { allStrapiProgramEnquiry } = useStaticQuery(
    graphql`
      query programEnquiryQuery {
        allStrapiProgramEnquiry {
          nodes {
            slug
            Button {
                heading
                redirect_to
              }
              heading
              sub_heading
            thumbnail{
              url
            }
            video {
							... on STRAPI__COMPONENT_UPLOAD_VIDEO_UPLOAD_VIDEO {
								id
								strapi_id
								strapi_component
								upload_video {
									url
									name
								}
							}
							... on STRAPI__COMPONENT_VIDEO_LINK_VIDEO_LINK {
								id
								video_link
								video_name
								strapi_component
							}
						}
          }
        }
      }
    `
  );
  return allStrapiProgramEnquiry.nodes;
};
