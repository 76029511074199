import { useStaticQuery, graphql } from "gatsby";

export const useEnrollmentData = () => {
    const { allStrapiEnrollmentDesign } = useStaticQuery(
        graphql`
        query enrollmentquery{
            allStrapiEnrollmentDesign{
                nodes{
                    heading
                    sub_heading
                    features{
                        UpperText
                    }
                    Button{
                        heading
                        redirect_to
                    }
                    slug
                }
            }
        }
        `
    )
    return allStrapiEnrollmentDesign.nodes
}