import { useStaticQuery, graphql } from "gatsby";

export const useTourFeeData = () => {
  const { allStrapiTourRealSchoolFeeTime } = useStaticQuery(
    graphql`
      query TourRealSchoolFeeTimeQuery {
        allStrapiTourRealSchoolFeeTime {
          nodes {
            heading
            sub_heading
            slug
            features{
              UpperText
              LowerText
              redirect_to
            }
          }
        }
      }
    `
  );
  return allStrapiTourRealSchoolFeeTime.nodes;
};
