import React from "react";
import Layout from "../components/layout.component";
import Enquiry from "../components/TourOfRealSchool/enquiry";
import Hero from "../components/commonComponent/hero";
import ProjectDesign from "../components/TourOfRealSchool/projectDesign";
import ClassExperience from "../components/TourOfRealSchool/classExperience";
import TimeTable from "../components/TourOfRealSchool/timeTable";
import FeeStructure from "../components/TourOfRealSchool/feeStructure";
import ClassCurriculum from '../components/TourOfRealSchool/classCurriculum'
import SEO from "../components/seo";
const tourofrealschool = () => {
    return (
        <Layout>
            <Hero slug="Tour-Of-RealSchool"/>
            <ClassCurriculum />
            <Enquiry/>
            <ProjectDesign />
            <ClassExperience />
            <TimeTable />
            <FeeStructure/>
        </Layout>
    )
}
export const Head = () => {
    return (
        <SEO description="This platform equips the kids with knowledge and creativity for their future. The activity classes for kids and the learning activities for kids are amazing. Join the educational activities for kids too."
            title="The Real School | Let's Gain Special Educational Activities for Kids with Interactivity"
            keywords="online school in india best online classes for kids best online learning for kids"
        />
    )
}

export default tourofrealschool
