import { Link } from "gatsby";
import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import styled from "styled-components";
import { useHeroData } from "../../hooks/programHero";
const Hero = (props) => {
    const data = useHeroData()
    let heroData = data.filter((data)=> data.slug === props.slug)[0]
    console.log(heroData);
    return (
        <HeroSectionStyled>
            <h1 key={`heading`} dangerouslySetInnerHTML={{ __html: `${heroData?.hero?.Heading}<br/> <span>${heroData?.hero?.HeadingRed} </span>` }} />

            <p
                key={`subheading`}
                id="hero_section_subheading"
                dangerouslySetInnerHTML={{ __html: heroData?.hero?.SubHeading }}
            />
            {heroData?.Button ?
                (
                    <Link to={`/form/?formName=${heroData?.Button?.redirect_to}`}>
                        <SessionBtnStyled
                            key={`button`}
                        >
                            {heroData?.Button?.heading}
                        </SessionBtnStyled>
                    </Link> 
                ): (
                    <></>
                )   
        }
            <div>
                <img
                    src={heroData?.hero?.Image?.url}
                    alt=""
                />
            </div>

        </HeroSectionStyled>
    );
};
const SessionBtnStyled = styled.button`
	cursor: pointer;
        color: black;
        background: #F9CE4B;
        border: none;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding: 10px 25px;
        outline: none;
        text-decoration: none;
	@media (max-width: 500px) {
		font-size: 1rem;
		line-height: 19px;
		padding: 0.90625em 1.84375em;
	}
	@media (max-width: 315px) {
		font-size: 0.8rem;
	}
`;

const HeroSectionStyled = styled.section`
	background-image: url("http://s3.us-east-2.wasabisys.com/ufaber-lms/files/a281399759dab24af288689491a1e3ef.166514973059579");
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100%;
	@media (max-width: 600px) {
		background-image: none;
		background-image: url("http://s3.us-east-2.wasabisys.com/ufaber-lms/files/abc9f8170a450b358bde834ee58625fd.svg") !important;
	}
	padding: 3rem 1rem 10.375rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	img {
		width: 100%;
		padding: 10px;
	}
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.3em;
		max-width: 14.875em;
	}
	& h1 span {
		color: #f05c5c;
	}
	#hero_section_subheading {
		margin-bottom: 2em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #2a264c;
		max-width: 26em;
	}

	@media (max-width: 600px) {
		padding: 3rem 1rem 4rem 1rem;

		& h1 {
			font-size: 1.75rem;
			line-height: 2.125rem;
			max-width: 13.875em;
		}
		#hero_section_subheading {
			font-size: 1rem;
			line-height: 1.1875em;
			max-width: 15em;
			margin-bottom: 1.5em;
		}
		#get_started {
			font-size: 1rem;
			line-height: 1.1875em;
			padding: 0.9em 1.75em;
		}
	}

	@media (max-width: 370px) {
		& h1 {
			font-size: 1.6rem;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 340px) {
		& h1 {
			font-size: 1.4rem;
			line-height: 1.7rem;
			max-width: 13.875em;
			margin-bottom: 0.4em;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
		#get_started {
			font-size: 0.9rem;
			padding: 0.9em 1.2em;
		}
	}
`;

export default Hero;
