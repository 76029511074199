import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useExperienceData } from "../../hooks/tour-class-experience";

const ClassExperience = () => {
    const data = useExperienceData()
	const experienceData = data[0]
    return (
		<EducationalGoalStyled>
			<EducationalGoalWrapperStyled>
			<h3 id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: experienceData?.heading }} />

			<EducationalGoalCardStyled>
                    {experienceData?.cards?.map((card,index) => {
						return (
								<div className="carousel_card" key={index}>
									<div className="carousel_image">
										<img src={card?.Image?.url} alt="" />
									</div>
									<p dangerouslySetInnerHTML={{ __html: card?.Title }}/>
								</div>

)
})}
</EducationalGoalCardStyled>

                
			</EducationalGoalWrapperStyled>
                    <ButtonWrapper> 
				<Link to='/project-result'>
                <ViewAllButtonStyle>{experienceData?.Button ?? "View All"}</ViewAllButtonStyle>
                </Link>
            </ButtonWrapper>
		</EducationalGoalStyled>
    )
}


const ButtonWrapper = styled.div`
display:flex;
justify-content: center;
align-items:center;
padding:10px;
`
const ViewAllButtonStyle = styled.button`
	border: 1px solid #f9ce4b;
	border-radius: 8px;
	padding: 16px 32px;
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	color: #2a264c;
	background-color: #f9ce4b;
	width: fit-content;
	cursor: pointer;
`;
const EducationalGoalStyled = styled.section`
	    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
`;

const EducationalGoalWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 0.75rem;
	#custom_counselling_heading{
		font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 58px;
    text-align: center;
    color: #2A264C;
	}
	& p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1rem;
		line-height: 30px;
		text-align: center;
		color: #2A264C;
		margin-bottom: 1rem;
	}
	@media (max-width: 541px) {
		& h2 {
			font-size: 2.7rem;
		}
	}
	@media (max-width: 500px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#custom_counselling_heading{
			font-size: 1.75rem;
    line-height: 34px;
	margin:20px
		}
	}

	@media (max-width: 355px) {
		& h2 {
			font-size: 1.5rem;
		}
	}

	@media (max-width: 315px) {
		& h2 {
			font-size: 1.3rem;
		}
	}
`;


const EducationalGoalCardStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5rem;
	width: 100%;
	margin-top:1rem;
	margin-bottom: 3rem;
	flex-wrap: wrap;
	.carousel_card {
		background: #ffffff;
		box-shadow: 0px 4px 25px rgba(17, 58, 104, 0.15);
		border-radius: 16px;
		padding: 0.5em;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.75em;
		min-width: 16.4375em;
		max-width: 16.4375em;
		margin-right: 1.5em;
		position: relative !important;
	}
	.carousel_image {
		border-radius: 12px;
		width: 100%;
		height: auto;
		overflow: hidden;
		position: relative;
	}
	.carousel_image img {
		min-width: 100%;
		max-width: 100%;
		min-height: 10rem;
		max-height:10rem;
		object-fit: cover;
	}
`;
export default ClassExperience