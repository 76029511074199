import { useStaticQuery, graphql } from "gatsby";

export const useHeroData = () => {
    const { allStrapiProgramHero } = useStaticQuery(
        graphql`
      query heroQuery {
        allStrapiProgramHero {
          nodes {
           hero{
            Heading
            SubHeading
            HeadingRed
            Image {
              url
            }
           }
           slug
           Button{
            heading
            redirect_to
           }
          }
        }
      }
    `
    );
    return allStrapiProgramHero.nodes;
};