import { useStaticQuery, graphql } from "gatsby";

export const useExperienceData = () => {
    const { allStrapiTourClassExperience } = useStaticQuery(
        graphql`
        query tourExperiencequery{
            allStrapiTourClassExperience{
                nodes{
                    heading
                    cards{
                        Title
                        Image{
                            url
                        }
                    }
                }
            }
        }
        `
    )
    return allStrapiTourClassExperience.nodes
}