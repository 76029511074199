import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useEnrollmentData } from "../../hooks/enrollmentDesign";


const ProjectDesign = () => {

    const data = useEnrollmentData()
	let programData = data.filter((data) => data.slug === "Tour-RealSchool-Design")[0]
    return (
        <BoardsAndProgramStyled>
            <BoardsAndProgramWrapperStyled>
				<p key={`heading`} id="multiple_tracks_heading1" dangerouslySetInnerHTML={{ __html: programData?.heading ?? "" }} />
                <BoardsAndProgramThreeWrapperStyled>
                    {programData?.features.map((detail) => {
                        return (
                            <BoardsAndProgramCardWrapperStyled key={detail.id}>
                                <div className="only_we_can_do_it_card" key={detail.id}>
                                    <h3
                                        key={`heading`}
                                        dangerouslySetInnerHTML={{
                                            __html: detail?.UpperText,
                                        }}
                                    />
                                </div>
                            </BoardsAndProgramCardWrapperStyled>
                        )
                    })}
                </BoardsAndProgramThreeWrapperStyled>
                <Link
                    to={
                        programData?.Button.redirect_to
                    }
                >
                    <ScheduleSessionBtnStyled id="schedule_session">{programData?.Button?.heading}</ScheduleSessionBtnStyled>
                </Link>
            </BoardsAndProgramWrapperStyled>
        </BoardsAndProgramStyled>
    );
};
const ScheduleSessionBtnStyled = styled.button`
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 24px;
	color: #2a264c;
	padding: 1em 2em;
	background: #f9ce4b;
	border-radius: 12px;
	@media (max-width: 500px) {
		font-size: 1rem;
		line-height: 19px;
		padding: 0.90625em 1.84375em;
	}
	@media (max-width: 315px) {
		font-size: 0.8rem;
	}
`;
const BoardsAndProgramStyled = styled.section``;
const BoardsAndProgramWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 3em;
	padding: 1em;
	padding-bottom: 7em;
	#multiple_tracks_heading1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2A264C;
	}
	.p-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-align: left;
		color: #2a264c;
	}
	@media (max-width: 500px) {
		#multiple_tracks_heading1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#multiple_tracks_heading2 {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 2.5em;
		}
	}
`;

const BoardsAndProgramThreeWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
		gap: 1.5em;
	}
`;

const BoardsAndProgramCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap: 1.5em;
	padding: 1.25em;
	background: #fff0eb;
	border-radius: 16px;
	min-width: 21em;
	max-width: 21em;
	.only_we_can_do_it_card h3 {
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		color: #2a264c;
	}

	@media (max-width: 500px) {
		padding: 0.75em;
		gap: 1em;
	}

	@media (max-width: 370px) {
		min-width: 20em;
		max-width: 20em;
	}

	@media (max-width: 315px) {
		min-width: 15.5em;
		max-width: 15.5em;
		gap: 1em;

		.fa-circle-chevron-right {
			font-size: 2rem;
		}
		.sa_wo_sa {
			font-size: 1.2rem;
		}
		.sa_wo_sa_subheading {
			font-size: 0.775rem;
		}
	}
`;
export default ProjectDesign;
